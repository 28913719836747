/* eslint-disable @typescript-eslint/naming-convention */
import { useToast } from '@chakra-ui/react'
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query'

import { baseStatsApiURL_NO_CACHE } from '@common/config'
import { ApiErrorMessage, MissingPlayersPostDataRequest, TMissingPlayerReason } from '@common/types'

import { useUser } from './useUser'

async function fetchMissingPlayerTypes(accessToken?: string) {
    if (typeof accessToken === 'undefined') {
        return Promise.reject(new Error('No access token provided'))
    }

    const response = await fetch(`${baseStatsApiURL_NO_CACHE}/default/data-provider/api/missing-player-types`, {
        headers: {
            Authentication: accessToken,
        },
    })

    if (response.status === 200) {
        return response.json()
    }

    const error = await response.json()

    return Promise.reject(new Error(error?.message ?? error?.error ?? '¯\\_(ツ)_/¯'))
}

async function fetchMissingPlayers(matchId?: string) {
    if (matchId === 'br:match:undefined' || typeof matchId === 'undefined') {
        return Promise.reject(new Error('No match id provided'))
    }

    const response = await fetch(
        `${baseStatsApiURL_NO_CACHE}/soccer/teams/missingplayers/match/comsuperbetsport/en-GB?match_id=${matchId}&no_lineups=true`,
    )

    if (response.status === 200) {
        return response.json()
    }

    const error = await response.json()

    return Promise.reject(new Error(error?.message ?? error?.error ?? '¯\\_(ツ)_/¯'))
}

async function sendMissingPlayers(matchId?: string, data?: MissingPlayersPostDataRequest, accessToken?: string) {
    if (typeof accessToken === 'undefined') {
        return Promise.reject(new Error('No access token provided'))
    }

    if (!data) {
        return Promise.reject(new Error('No missing players data provided'))
    }

    const response = await fetch(`${baseStatsApiURL_NO_CACHE}/default/data-provider/api/save-missing-players`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authentication: accessToken,
        },
        body: JSON.stringify(data),
    })

    if (response.status === 200) {
        return response.json()
    }

    const error = await response.json()

    return Promise.reject(new Error(error?.message ?? error?.error ?? '¯\\_(ツ)_/¯'))
}

function getMissingPlayers(players) {
    const transformedPlayers = players?.map(player => ({
        name: player.player?.name,
        id: player.player?.id,
        shirt_number: player.shirt_number?.value ?? '-',
        reason: player.reason,
        type: player.type_int_value?.value ?? 0,
        source: player.source?.value ?? 'provider',
    }))

    return Object.groupBy(transformedPlayers, ({ reason }: { reason: TMissingPlayerReason }) => String(reason))
}

function selectMissingPlayers(missingPlayers) {
    return {
        team1MissingPlayers: getMissingPlayers(missingPlayers.team1_missing_players?.missing_players),
        team2MissingPlayers: getMissingPlayers(missingPlayers.team2_missing_players?.missing_players),
    }
}

export function useMissingPlayers(matchId?: string, options: Pick<UseQueryOptions, 'enabled'> = {}) {
    const { userData } = useUser()
    const queryClient = useQueryClient()
    const toast = useToast()

    const {
        mutateAsync: saveMissingPlayers,
        isPending: isSendingMissingPlayers,
        isSuccess: missingPlayerSent,
    } = useMutation({
        mutationFn: async (createData: MissingPlayersPostDataRequest) =>
            sendMissingPlayers(matchId, createData, userData?.accessToken),
        onSettled: async () => {
            await new Promise(resolve => {
                setTimeout(resolve, 2000)
            })

            queryClient.invalidateQueries({ queryKey: ['missingPlayers', matchId] })
        },
        onSuccess: () => {
            toast({
                position: 'top',
                title: 'Missing players saved!',
                status: 'success',
            })
        },
        onError: error => {
            if (error.message !== ApiErrorMessage.UNAUTHORIZED) {
                toast({
                    position: 'top',
                    description: `Error saving Missing players: ${error.message}`,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        },
    })

    const {
        data: missingPlayers,
        isLoading,
        isError,
    } = useQuery({
        enabled: !!matchId,
        ...options,
        queryKey: ['missingPlayers', matchId],
        queryFn: () => fetchMissingPlayers(String(matchId)),
        select: selectMissingPlayers,
    })

    const {
        data: missingPlayersTypes,
        isLoading: isMissingPlayerTypesLoading,
        isError: isMissingPlayerTypesError,
    } = useQuery({
        ...options,
        queryKey: ['missingPlayerTypes', userData?.accessToken],
        queryFn: () => fetchMissingPlayerTypes(userData?.accessToken),
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
    })

    return {
        missingPlayers,
        isLoading,
        isError,
        saveMissingPlayers,
        isSendingMissingPlayers,
        missingPlayerSent,
        missingPlayersTypes,
        isMissingPlayerTypesLoading,
        isMissingPlayerTypesError,
    }
}
