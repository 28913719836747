import { FaCircle } from 'react-icons/fa'
import { DeleteIcon } from '@chakra-ui/icons'
import { Box, Checkbox, Container, Divider, Fade, Flex, Icon, IconButton } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'

import { SortableItem } from '@components/DragAndDrop/SortableItem'

import { MissingPlayer, MissingPlayerReason } from '@common/types'

import { useMissingPlayers } from '@queries/useMissingPlayers'

import { ListPlayerBasicInfo } from './ListPlayerBasicInfo'

interface ListPlayerProps {
    player: MissingPlayer
    index: number | string
    id: string
    updatePlayer: (player: MissingPlayer) => void
    deletePlayer: (player: MissingPlayer) => void
    handlePlayerClick?: (player: MissingPlayer) => void
}

const MissingPlayerReasonTypesMapping = {
    [MissingPlayerReason.MISSINGPLAYERREASON_INJURY]: 'injury_types',
    [MissingPlayerReason.MISSINGPLAYERREASON_SUSPENSION]: 'suspension_types',
    [MissingPlayerReason.MISSINGPLAYERREASON_DOUBTFUL]: 'injury_types',
}

const getPrettyString = (value?: string) => value?.replace(/_/g, ' ')

export function ListPlayer({ player, index, id, updatePlayer, deletePlayer, handlePlayerClick }: ListPlayerProps) {
    const { missingPlayersTypes, isMissingPlayerTypesLoading } = useMissingPlayers()

    const label =
        getPrettyString(
            missingPlayersTypes?.[MissingPlayerReasonTypesMapping[player?.reason]]?.find(
                type => type[1] === player?.type,
            )?.[0],
        ) ?? 'no reason'

    const selectReasonTypeOptions = missingPlayersTypes?.[MissingPlayerReasonTypesMapping[player?.reason]]?.map(
        type => ({
            value: type[1],
            label: getPrettyString(type[0].replace(/_/g, ' ')),
        }),
    )

    const isDoubtfulPlayer = player.reason === MissingPlayerReason.MISSINGPLAYERREASON_DOUBTFUL

    const isSuspendedPlayer = player.reason === MissingPlayerReason.MISSINGPLAYERREASON_SUSPENSION

    // removes the player from the current list & then adds it to the new one
    const handleDoubtfulPlayerCheckbox = e => {
        deletePlayer(player)

        updatePlayer({
            ...player,
            reason: e.target.checked
                ? MissingPlayerReason.MISSINGPLAYERREASON_DOUBTFUL
                : MissingPlayerReason.MISSINGPLAYERREASON_INJURY,
            source: 'manual',
        })
    }

    const handleTypeChange = event => {
        updatePlayer({
            ...player,
            type: event.value,
        })
    }

    const isFakePlayer = !player.id || player.id.includes('fake')

    return (
        <SortableItem key={`${id}-${index}`} id={id} data={{ player }}>
            {player ? (
                <Fade in key={`${id}-${index}`} transition={{ exit: { duration: 1 }, enter: { duration: 1 } }}>
                    <Flex cursor="grab" p={3} gap={3} alignItems="center" justifyContent="space-between">
                        <Flex alignItems="center">
                            <Checkbox
                                isDisabled={isSuspendedPlayer}
                                defaultChecked={isDoubtfulPlayer}
                                isChecked={isDoubtfulPlayer}
                                onChange={handleDoubtfulPlayerCheckbox}
                                size="lg"
                                colorScheme="gray"
                            />
                            <ListPlayerBasicInfo player={player} handlePlayerClick={handlePlayerClick} />
                            {isFakePlayer && <Icon as={FaCircle} boxSize="0.6rem" color="#FAAD14" />}
                        </Flex>
                        <Flex gap={4}>
                            <Container width="100%" maxW="200px" m={0} p={0} backgroundColor="whiteAlpha.700">
                                <Select
                                    options={selectReasonTypeOptions}
                                    size="sm"
                                    onChange={e => handleTypeChange(e)}
                                    value={{ label, value: player.type }}
                                    isLoading={isMissingPlayerTypesLoading}
                                />
                            </Container>
                            <IconButton
                                variant="outline"
                                flex={1}
                                colorScheme="red"
                                aria-label="Delete event"
                                size="sm"
                                icon={<DeleteIcon />}
                                onClick={() => deletePlayer(player)}
                            />
                        </Flex>
                    </Flex>
                </Fade>
            ) : (
                /* this is a temp hack for creating the sortableItem ref if the list is empty
                -> I'll think of a better solution */
                <Box minH="100px" />
            )}
            <Divider borderColor="blackAlpha.50" />
        </SortableItem>
    )
}
