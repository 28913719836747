/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { lazy, Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, SubmitHandler } from 'react-hook-form'
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    HStack,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Radio,
    RadioGroup,
    VStack,
} from '@chakra-ui/react'
import { useBlocker } from '@tanstack/react-router'
import { components, CreatableSelect, OptionProps, PropsValue, Select } from 'chakra-react-select'
import { format, parseISO } from 'date-fns'

import { useSearchState } from '@hooks/useSearchState'
import { useZodForm } from '@hooks/useZodForm'

import {
    EventFormData,
    EventSchema,
    MatchEventEditorCardExtraInfo,
    MatchEventEditorEventType,
    MatchEventEditorGoalExtraInfo,
    MatchEventEditorVarExtraInfo,
    MatchEventPeriodNumber,
    TMatchEventEditorEventType,
    TMatchEventEditorGoalExtraInfo,
} from '@common/types'

import { useSelectedEventContext } from '@contexts/SelectedEventContext'

import { useMatchEventEditorData } from '@queries/useMatchEventEditorData'

import type { Option } from './utils'
import {
    createOptions,
    extractPlayersFromEvents,
    findOption,
    formatMatchTime,
    getMatchEditorEventTypeLabel,
    getPlayerIdFromOption,
    getPlayerNameFromOption,
    getPlayerOptionFromId,
    makePrettierLabel,
    playerSearchFilter,
} from './utils'

const ReactHookFormDevTools =
    process.env.NODE_ENV === 'production'
        ? () => null
        : lazy(() =>
              import('@hookform/devtools').then(res => ({
                  default: res.DevTool,
              })),
          )

type PlayerOptionProps = OptionProps<Option, false>

function PlayerOption(props: PlayerOptionProps) {
    return (
        <components.Option {...props}>
            <HStack justifyContent="space-between">
                <Box as="span">{props.data.label}</Box>
                <Box as="span" fontFamily="monospace">
                    [{props.data.value}]
                </Box>
            </HStack>
        </components.Option>
    )
}

function simulateAsyncTimeout(ms: number) {
    return new Promise(resolve => {
        setTimeout(resolve, ms)
    })
}

const initialDefaultValues: EventFormData = {
    team_id: { value: '', label: '' },
    type: { value: '', label: '' },
    match_time: {
        minutes: 0,
        seconds: 0,
        additionalMinutes: '0',
    },
    player1_id: { value: '', label: '' },
    player2_id: { value: '', label: '' },
    team1_score: 0,
    team2_score: 0,
    period_number: { value: '', label: '' },
    extra_info: 0,
}

export function Event() {
    const [selectedTeam, setSelectedTeam] = useState<string>()
    const [selectedEventType, setSelectedEventType] = useState<TMatchEventEditorEventType>()
    const [selectedExtraInfo, setSelectedExtraInfo] = useState<TMatchEventEditorGoalExtraInfo>()
    const [isAdditionalTimeSelected, setIsAdditionalTimeSelected] = useState<boolean>(false)
    const [fakePlayers, setFakePlayers] = useState<Option[]>([])
    const [isCreatingPlayer, setIsCreatingPlayer] = useState(false)
    const [selectedPeriod, setSelectedPeriod] = useState<number>(MatchEventPeriodNumber.first_half)
    const [minuteRange, setMinuteRange] = useState<{ min: number; max: number }>(() => ({ min: 0, max: 45 }))

    const { data, events, lineups, update, create } = useMatchEventEditorData()
    const { selectedEvent, setSelectedEvent } = useSelectedEventContext()
    const [{ matchId: selectedMatchId }] = useSearchState()

    const previousSelectedMatchIdRef = useRef(selectedMatchId)
    const previousSelectedEventIdRef = useRef(selectedEvent?.id)
    const additionalTimeStateWasSetByUserRef = useRef(false)

    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,
        formState: { errors, isSubmitting, isDirty },
    } = useZodForm({
        schema: EventSchema,
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: initialDefaultValues,
    })

    useBlocker(() => window.confirm('Are you sure you want to leave? You have unsaved changes!'), isDirty)

    const teamOptions = useMemo<Option[]>(() => {
        if (!data) {
            return []
        }

        return [
            {
                value: data?.team1_info?.id,
                label: data?.team1_info?.name,
            },
            {
                value: data?.team2_info?.id,
                label: data?.team2_info?.name,
            },
        ]
    }, [data])

    const eventFakePlayers = useMemo(() => {
        if (!data || !events) {
            return []
        }

        return {
            [data?.team1_info?.id]: extractPlayersFromEvents(data?.team1_info?.id, events),
            [data?.team2_info?.id]: extractPlayersFromEvents(data?.team2_info?.id, events),
        }
    }, [data, events])

    const playerOptions = useMemo(() => {
        if (!selectedTeam) {
            return []
        }

        let teamToUse = lineups?.[selectedTeam]

        // If it's own goal, player suggestions should be inverted
        if (
            Number(selectedExtraInfo) === MatchEventEditorGoalExtraInfo.own_goal &&
            Number(selectedEventType) === MatchEventEditorEventType.goal
        ) {
            teamToUse = lineups?.[selectedTeam === data?.team1_info?.id ? data?.team2_info?.id : data?.team1_info?.id]
        }

        const existingFakePlayers = eventFakePlayers?.[selectedTeam]?.length ? [...eventFakePlayers[selectedTeam]] : []

        if (teamToUse?.length) {
            const realPlayers = teamToUse?.map(({ id, name }) => ({ value: String(id), label: name })) ?? []

            return [...fakePlayers, ...existingFakePlayers, ...realPlayers]
        }

        return [...fakePlayers, ...existingFakePlayers]
    }, [lineups, eventFakePlayers, fakePlayers, selectedTeam, selectedExtraInfo, selectedEventType])

    const typeOptions = useMemo(() => createOptions(MatchEventEditorEventType, getMatchEditorEventTypeLabel), [])

    const periodOptions = useMemo(() => createOptions(MatchEventPeriodNumber, makePrettierLabel), [])

    const extraInfoOptions = useMemo(() => {
        switch (Number(selectedEventType)) {
            case MatchEventEditorEventType.goal:
                return createOptions(MatchEventEditorGoalExtraInfo, makePrettierLabel)

            case MatchEventEditorEventType.yellow_card:

            case MatchEventEditorEventType.yellowred_card:

            case MatchEventEditorEventType.red_card:
                return createOptions(MatchEventEditorCardExtraInfo, makePrettierLabel)

            case MatchEventEditorEventType.var_over:
                return createOptions(MatchEventEditorVarExtraInfo, makePrettierLabel)

            default:
                return []
        }
    }, [selectedEventType])

    const { player1Label, player2Label } = useMemo(() => {
        const EMPTY_LABELS = { player1Label: '', player2Label: '' }
        switch (Number(selectedEventType)) {
            case MatchEventEditorEventType.goal:
                return { player1Label: 'Goalscorer', player2Label: 'Player to assist' }

            case MatchEventEditorEventType.yellow_card:

            case MatchEventEditorEventType.yellowred_card:

            case MatchEventEditorEventType.red_card: {
                const extraInfoTypesWithoutPlayer = [
                    MatchEventEditorCardExtraInfo.manager,
                    MatchEventEditorCardExtraInfo.staff,
                ].map(Number)

                let tempPlayer1Label = 'Player getting booked'

                if (selectedExtraInfo && extraInfoTypesWithoutPlayer.includes(Number(selectedExtraInfo))) {
                    tempPlayer1Label = ''
                }

                return {
                    player1Label: tempPlayer1Label,
                    player2Label: '',
                }
            }

            case MatchEventEditorEventType.var_over:
                return EMPTY_LABELS

            case MatchEventEditorEventType.substitution:
                return {
                    player1Label: 'Player out',
                    player2Label: 'Player in',
                }

            default:
                return EMPTY_LABELS
        }
    }, [selectedEventType, selectedExtraInfo])

    const handleReset = useCallback(
        (defaultValues?: unknown) => {
            const shouldKeepDirtyValues = selectedEvent?.id === previousSelectedEventIdRef.current

            if (defaultValues) {
                reset(defaultValues, { keepDirtyValues: shouldKeepDirtyValues })

                return
            }

            const resetTeam = teamOptions.find(option => option.value === String(selectedTeam))
            const resetType = typeOptions.find(option => option.value === String(selectedEventType))

            const resetValues = {
                ...initialDefaultValues,
                team_id: resetTeam,
                type: resetType,
            }

            reset(resetValues, { keepDirtyValues: shouldKeepDirtyValues })
        },
        [teamOptions, typeOptions, reset, selectedEvent?.id],
    )

    const handleCreateFakePlayer = async (inputValue: string, fieldName: 'player1_id' | 'player2_id') => {
        setIsCreatingPlayer(true)

        const newFakePlayer = {
            value: `is:fake:player:${fakePlayers.length + 1}`,
            label: inputValue,
        }

        if (!fakePlayers.find(fakePlayer => fakePlayer.value === newFakePlayer.value)) {
            setFakePlayers(currentFakePlayers => [newFakePlayer, ...currentFakePlayers])
        }

        await simulateAsyncTimeout(100)

        setValue(fieldName, newFakePlayer, { shouldDirty: true })

        setIsCreatingPlayer(false)
    }

    const handleAdditionalTimeToggleByUser = () => {
        const newAdditionalTimeStatus = !isAdditionalTimeSelected

        additionalTimeStateWasSetByUserRef.current = true

        setIsAdditionalTimeSelected(newAdditionalTimeStatus)
    }

    const prepareRequestPayload = (formData: EventFormData) => ({
        team_id: formData.team_id?.value,
        type: Number(formData.type?.value),
        match_time: formatMatchTime(formData.match_time),
        timestamp: format(parseISO(selectedEvent?.time ?? new Date().toISOString()), 'T'),
        player1_id: getPlayerIdFromOption(formData.player1_id),
        player1_name: getPlayerNameFromOption(formData.player1_id),
        player2_id: getPlayerIdFromOption(formData.player2_id),
        player2_name: getPlayerNameFromOption(formData.player2_id),
        team1_score: Number(formData.team1_score),
        team2_score: Number(formData.team2_score),
        extra_info: !Number.isNaN(formData.extra_info) ? Number(formData.extra_info) : undefined,
        period_number: Number(formData.period_number?.value),
    })

    const handleOnSubmit: SubmitHandler<EventFormData> = async formData => {
        try {
            const requestPayload = prepareRequestPayload(formData)

            if (formData?.id || selectedEvent?.id) {
                const updateRequestPayload = {
                    id: String(formData.id ?? selectedEvent?.id),
                    ...requestPayload,
                }

                await update(updateRequestPayload, {
                    onSettled: () => {
                        handleReset()

                        setFakePlayers([]) // Reset fake players as we can't map them to API fake players
                    },
                })

                return
            }

            await create(requestPayload, {
                onSettled: () => {
                    handleReset()

                    setFakePlayers([]) // Reset fake players as we can't map them to API fake players
                },
            })
        } catch (error) {
            await Promise.reject(new Error('¯\\_(ツ)_/¯'))
        }
    }

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (type !== 'change') {
                return
            }

            if (name === 'team_id' && selectedTeam !== value.team_id?.value) {
                setSelectedTeam(value.team_id?.value)
            }

            if (name === 'type' && selectedEventType !== Number(value.type?.value)) {
                setSelectedEventType(value.type?.value as unknown as TMatchEventEditorEventType)
            }

            if (name === 'extra_info' && selectedExtraInfo !== Number(value.extra_info)) {
                setSelectedExtraInfo(value.extra_info as unknown as TMatchEventEditorGoalExtraInfo)
            }

            if (name === 'period_number' && selectedPeriod !== Number(value.period_number?.value)) {
                setSelectedPeriod(Number(value.period_number?.value))
            }
        })

        return () => subscription.unsubscribe()
    }, [watch, selectedTeam, selectedPeriod, selectedEventType, selectedExtraInfo])

    useEffect(() => {
        if (!selectedEvent) {
            handleReset()
        } else {
            setSelectedTeam(String(selectedEvent?.team_id))

            setSelectedEventType(selectedEvent?.type)

            const timeParts = selectedEvent?.match_time?.split('+') ?? ['00:00']
            const [matchTime, additionalTime] = timeParts

            const [matchMinutes, matchSeconds] = matchTime.split(':').map(Number)

            let additionalMinutes = 0
            let seconds = matchSeconds

            // Only toggle this value if user didn't mess around with the additional time checkbox for this event
            if (additionalTime) {
                const [extraMinutes, extraSeconds] = additionalTime.split(':').map(Number)

                additionalMinutes = extraMinutes

                seconds = extraSeconds

                setIsAdditionalTimeSelected(true)
            } else {
                setIsAdditionalTimeSelected(false)
            }

            const periodNumber = selectedEvent?.period_number
                ? findOption(String(selectedEvent?.period_number), periodOptions)
                : periodOptions[0]

            setSelectedPeriod(Number(periodNumber?.value))

            handleReset({
                team_id: findOption(String(selectedEvent?.team_id), teamOptions),
                type: findOption(String(selectedEvent?.type), typeOptions),
                match_time: {
                    minutes: matchMinutes,
                    seconds,
                    additionalMinutes: String(additionalMinutes),
                },
                timestamp:
                    selectedEvent?.timestamp ?? format(parseISO(selectedEvent?.time ?? new Date().toISOString()), 'T'),
                // Fallback handles fake player entries
                player1_id: getPlayerOptionFromId(selectedEvent?.player1_id, playerOptions),
                player2_id: getPlayerOptionFromId(selectedEvent?.player2_id, playerOptions),
                team1_score: Number(selectedEvent?.team1_score || selectedEvent?.match_score?.team1),
                team2_score: Number(selectedEvent?.team2_score || selectedEvent?.match_score?.team2),
                extra_info: Number(findOption(String(selectedEvent.extra_info), extraInfoOptions)?.value),
                period_number: periodNumber,
            })
        }
    }, [selectedEvent?.id, playerOptions])

    useEffect(() => {
        previousSelectedMatchIdRef.current = selectedMatchId
    }, [selectedMatchId])

    useEffect(() => {
        previousSelectedEventIdRef.current = selectedEvent?.id
    }, [selectedEvent])

    useEffect(() => {
        if (selectedMatchId !== previousSelectedMatchIdRef.current) {
            setSelectedEvent(null)

            handleReset()
        }
    }, [selectedMatchId, handleReset])

    useEffect(() => {
        const timeParts = selectedEvent?.match_time?.split('+') ?? ['00:00']

        const [matchTime, additionalTime] = timeParts

        const [matchMinutes, matchSeconds] = matchTime.split(':').map(Number)

        let additionalMinutes = 0
        let additionalSeconds = 0

        if (additionalTime) {
            const [extraMinutes, extraSeconds] = additionalTime.split(':').map(Number)

            additionalMinutes = extraMinutes

            additionalSeconds = extraSeconds
        }

        switch (selectedPeriod) {
            case MatchEventPeriodNumber.first_half: {
                setMinuteRange({ min: 0, max: 45 })

                setValue(
                    'match_time',
                    {
                        minutes: isAdditionalTimeSelected ? 45 : matchMinutes,
                        seconds: isAdditionalTimeSelected ? additionalSeconds : matchSeconds,
                        additionalMinutes: String(additionalMinutes),
                    },
                    { shouldDirty: false },
                )

                break
            }

            case MatchEventPeriodNumber.second_half: {
                setMinuteRange({ min: 45, max: 90 })

                setValue(
                    'match_time',
                    {
                        minutes: isAdditionalTimeSelected ? 90 : matchMinutes,
                        seconds: isAdditionalTimeSelected ? additionalSeconds : matchSeconds,
                        additionalMinutes: String(additionalMinutes),
                    },
                    { shouldDirty: false },
                )

                break
            }

            case MatchEventPeriodNumber.first_half_extra_time: {
                setMinuteRange({ min: 90, max: 105 })

                setValue(
                    'match_time',
                    {
                        minutes: isAdditionalTimeSelected ? 105 : matchMinutes,
                        seconds: isAdditionalTimeSelected ? additionalSeconds : matchSeconds,
                        additionalMinutes: String(additionalMinutes),
                    },
                    { shouldDirty: false },
                )

                break
            }

            case MatchEventPeriodNumber.second_half_extra_time: {
                setMinuteRange({ min: 105, max: 120 })

                setValue(
                    'match_time',
                    {
                        minutes: isAdditionalTimeSelected ? 120 : matchMinutes,
                        seconds: isAdditionalTimeSelected ? additionalSeconds : matchSeconds,
                        additionalMinutes: String(additionalMinutes),
                    },
                    { shouldDirty: false },
                )

                break
            }

            case MatchEventPeriodNumber.penalties: {
                setMinuteRange({ min: 120, max: 150 })

                setValue(
                    'match_time',
                    {
                        minutes: isAdditionalTimeSelected ? 150 : matchMinutes,
                        seconds: isAdditionalTimeSelected ? additionalSeconds : matchSeconds,
                        additionalMinutes: String(additionalMinutes),
                    },
                    { shouldDirty: false },
                )

                break
            }

            default:
                setMinuteRange({ min: 0, max: 45 })

                break
        }
    }, [isAdditionalTimeSelected, selectedPeriod])

    const hasMatchTimeError =
        !!errors.match_time?.root?.message ||
        !!errors.match_time?.minutes?.message ||
        !!errors.match_time?.seconds?.message ||
        (isAdditionalTimeSelected && !!errors.match_time?.additionalMinutes?.message)

    const buttonLabel = selectedEvent?.id ? 'Save Event' : 'Add Event'

    const shouldRenderPlayerSelection = !!player1Label.length
    const shouldRenderResultSelection = Number(selectedEventType) === MatchEventEditorEventType.goal
    const shouldRenderExtraInfoSelection = !!extraInfoOptions.length

    const player1Value = watch('player1_id')
    const player2Value = watch('player2_id')
    const eventType = watch('type')

    const getIsPlayersError = () =>
        Number(eventType?.value) === MatchEventEditorEventType.substitution
            ? !player1Value?.value !== !player2Value?.value
            : false

    const isValid = Object.keys(errors).length === 0 && !getIsPlayersError()

    const isLoading = isSubmitting

    return (
        <form noValidate autoComplete="off" onSubmit={handleSubmit(handleOnSubmit)}>
            <Controller
                control={control}
                name="team_id"
                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
                    <FormControl isInvalid={!!error} isRequired isDisabled={!!selectedEvent} mb={8}>
                        <FormLabel htmlFor="team_id">Select team</FormLabel>
                        <Select
                            id="team_id"
                            name={name}
                            ref={ref}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value as unknown as PropsValue<Option>}
                            placeholder="Select team"
                            options={teamOptions}
                        />
                        <FormErrorMessage>{errors.team_id?.message}</FormErrorMessage>
                    </FormControl>
                )}
            />
            <HStack mb={8} alignItems="flex-start">
                <Controller
                    control={control}
                    name="type"
                    render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
                        <FormControl isInvalid={!!error} isRequired maxW="50%">
                            <FormLabel htmlFor="type">Select event type</FormLabel>
                            <Select
                                id="type"
                                isSearchable
                                name={name}
                                ref={ref}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value as unknown as PropsValue<Option>}
                                placeholder="Select event type"
                                options={typeOptions}
                            />
                            <FormErrorMessage>{errors.type?.message}</FormErrorMessage>
                        </FormControl>
                    )}
                />
                <Controller
                    control={control}
                    name="period_number"
                    render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
                        <FormControl isInvalid={!!error} isRequired maxW="50%">
                            <FormLabel htmlFor="period_number">Select period</FormLabel>
                            <Select
                                id="period_number"
                                name={name}
                                ref={ref}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value as unknown as PropsValue<Option>}
                                placeholder="Select period"
                                options={periodOptions}
                            />
                            <FormErrorMessage>{errors.period_number?.message}</FormErrorMessage>
                        </FormControl>
                    )}
                />
            </HStack>
            <HStack mb={8}>
                <VStack>
                    <FormControl isInvalid={hasMatchTimeError} isRequired>
                        <HStack mb={2}>
                            <FormLabel htmlFor="match_time.minutes" mb={0}>
                                Time
                            </FormLabel>
                            <Checkbox
                                isRequired={false}
                                formNoValidate
                                defaultChecked={isAdditionalTimeSelected}
                                isChecked={isAdditionalTimeSelected}
                                onChange={handleAdditionalTimeToggleByUser}>
                                Additional time
                            </Checkbox>
                        </HStack>
                        <HStack>
                            <Controller
                                control={control}
                                name="match_time.minutes"
                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                    <FormControl>
                                        <NumberInput
                                            max={minuteRange.max}
                                            min={minuteRange.min}
                                            defaultValue={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                        >
                                            <NumberInputField ref={ref} name={name} />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>
                                        <FormHelperText>Minutes</FormHelperText>
                                    </FormControl>
                                )}
                            />
                            {isAdditionalTimeSelected && (
                                <Controller
                                    control={control}
                                    name="match_time.additionalMinutes"
                                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                        <FormControl>
                                            <NumberInput
                                                max={30}
                                                min={0}
                                                defaultValue={value}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value}
                                            >
                                                <NumberInputField ref={ref} name={name} />
                                                <NumberInputStepper>
                                                    <NumberIncrementStepper />
                                                    <NumberDecrementStepper />
                                                </NumberInputStepper>
                                            </NumberInput>
                                            <FormHelperText>Minutes (additional)</FormHelperText>
                                        </FormControl>
                                    )}
                                />
                            )}
                            <Controller
                                control={control}
                                name="match_time.seconds"
                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                    <FormControl>
                                        <NumberInput
                                            max={59}
                                            min={0}
                                            defaultValue={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            isRequired
                                        >
                                            <NumberInputField ref={ref} name={name} />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>
                                        <FormHelperText>Seconds</FormHelperText>
                                    </FormControl>
                                )}
                            />
                        </HStack>
                        <FormErrorMessage>{errors.match_time?.root?.message}</FormErrorMessage>
                        <FormErrorMessage>{errors.match_time?.minutes?.message}</FormErrorMessage>
                        <FormErrorMessage>{errors.match_time?.additionalMinutes?.message}</FormErrorMessage>
                        <FormErrorMessage>{errors.match_time?.seconds?.message}</FormErrorMessage>
                    </FormControl>
                </VStack>
            </HStack>
            {shouldRenderPlayerSelection && (
                <HStack mb={8} alignItems="flex-start">
                    <Controller
                        control={control}
                        name="player1_id"
                        render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
                            <FormControl isInvalid={getIsPlayersError()}>
                                <FormLabel htmlFor={name}>{player1Label}</FormLabel>
                                <CreatableSelect
                                    id={name}
                                    components={{ Option: PlayerOption }}
                                    filterOption={playerSearchFilter}
                                    isSearchable
                                    isClearable
                                    name={name}
                                    ref={ref}
                                    onChange={newValue => onChange(newValue || undefined)}
                                    onBlur={onBlur}
                                    isLoading={isCreatingPlayer}
                                    onCreateOption={newPlayerValue => handleCreateFakePlayer(newPlayerValue, name)}
                                    value={value as unknown as PropsValue<Option>}
                                    placeholder={`Select ${player1Label}`}
                                    options={playerOptions}
                                />
                                <FormHelperText>
                                    Note: You can search by player ID or manually enter the name
                                </FormHelperText>
                                {getIsPlayersError() && (
                                    <FormErrorMessage>Select either both players or none</FormErrorMessage>
                                )}
                            </FormControl>
                        )}
                    />
                    {!!player2Label.length && (
                        <Controller
                            control={control}
                            name="player2_id"
                            render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
                                <FormControl isInvalid={getIsPlayersError()}>
                                    <FormLabel htmlFor={name}>{player2Label}</FormLabel>
                                    <CreatableSelect
                                        id={name}
                                        components={{ Option: PlayerOption }}
                                        filterOption={playerSearchFilter}
                                        isSearchable
                                        isClearable
                                        name={name}
                                        ref={ref}
                                        onChange={newValue => onChange(newValue || undefined)}
                                        onBlur={onBlur}
                                        isLoading={isCreatingPlayer}
                                        onCreateOption={newPlayerValue => handleCreateFakePlayer(newPlayerValue, name)}
                                        value={value as unknown as PropsValue<Option>}
                                        placeholder={`Select ${player2Label}`}
                                        options={playerOptions}
                                    />
                                    <FormHelperText>
                                        Note: You can search by player ID or manually enter the name
                                    </FormHelperText>
                                </FormControl>
                            )}
                        />
                    )}
                </HStack>
            )}
            {shouldRenderResultSelection && (
                <HStack mb={8}>
                    <FormControl isInvalid={!!errors.team1_score || !!errors.team2_score} isRequired>
                        <FormLabel>Result</FormLabel>
                        <HStack>
                            <Controller
                                control={control}
                                name="team1_score"
                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                    <FormControl maxW="25%">
                                        <NumberInput
                                            min={0}
                                            defaultValue={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            isRequired
                                        >
                                            <NumberInputField ref={ref} name={name} />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>
                                        <FormHelperText>Home</FormHelperText>
                                    </FormControl>
                                )}
                            />
                            <Controller
                                control={control}
                                name="team2_score"
                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                    <FormControl maxW="25%">
                                        <NumberInput
                                            min={0}
                                            defaultValue={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            isRequired
                                        >
                                            <NumberInputField ref={ref} name={name} />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>
                                        <FormHelperText>Away</FormHelperText>
                                    </FormControl>
                                )}
                            />
                        </HStack>
                        <FormErrorMessage>
                            {errors.team1_score?.message || errors.team2_score?.message}
                        </FormErrorMessage>
                    </FormControl>
                </HStack>
            )}
            {shouldRenderExtraInfoSelection && (
                <Controller
                    control={control}
                    name="extra_info"
                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                        <FormControl
                            isInvalid={!!errors.extra_info}
                            isRequired={selectedEventType !== MatchEventEditorEventType.goal}
                            mb={8}
                        >
                            <FormLabel htmlFor={name}>Extra info</FormLabel>
                            <RadioGroup onChange={onChange} value={String(value)} name={name} onBlur={onBlur} ref={ref}>
                                <VStack alignItems="flex-start">
                                    {extraInfoOptions.map(({ value: optionValue, label }) => (
                                        <Radio key={optionValue} value={optionValue}>
                                            {label}
                                        </Radio>
                                    ))}
                                </VStack>
                            </RadioGroup>
                            <FormErrorMessage>{errors.extra_info?.message}</FormErrorMessage>
                        </FormControl>
                    )}
                />
            )}
            <Button mt={8} colorScheme="teal" isLoading={isLoading} isDisabled={!isValid} type="submit">
                {buttonLabel}
            </Button>
            <Suspense fallback={null}>
                {/* @ts-expect-error I am not typing internal props for React Hook Form like _reset, nonsense */}
                <ReactHookFormDevTools control={control} />
            </Suspense>
        </form>
    )
}
