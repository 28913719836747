import { FaCircle } from 'react-icons/fa'
import { IoMdClose } from 'react-icons/io'
import { Box, Button, Icon } from '@chakra-ui/react'

import { Draggable } from './LineupEditModal/Draggable'
import { PlayerBasicInfo } from './PlayerBasicInfo'

interface PlayerProps {
    player: any
    variant?: 'dark' | 'light'
    isHomeTeam: boolean
    isSquadPlayer?: boolean
    isGoalKeeper?: boolean
    currentActivePlayer?: any
    onDeletePlayerClick?: (rowInd: number, colInd?: number) => void
    colInd?: number
    rowInd?: number
    index?: number
    isSubstitution?: boolean
    activeDroppable?: any
    droppableId?: string
    onPlayerClick?: (player) => void
    isShirtNumberTaken?: boolean
}

export function Player({
    player,
    isHomeTeam,
    isGoalKeeper,
    isSquadPlayer,
    currentActivePlayer,
    onDeletePlayerClick,
    variant,
    colInd,
    rowInd,
    index,
    isSubstitution,
    activeDroppable,
    droppableId,
    onPlayerClick,
    isShirtNumberTaken,
}: PlayerProps) {
    const isCurrentActivePlayer = currentActivePlayer?.name === player.name
    const isPlayerActiveDroppable = activeDroppable?.id === droppableId && !isCurrentActivePlayer
    const shouldHideDeleteButton = isCurrentActivePlayer || isSquadPlayer || isPlayerActiveDroppable

    const handleDeletePlayerClick = () => {
        if (onDeletePlayerClick) {
            if (isSubstitution && index !== undefined) {
                onDeletePlayerClick(index)
            }

            if (rowInd !== undefined && colInd !== undefined) {
                onDeletePlayerClick(rowInd, colInd)
            }
        }
    }

    const playerPosition = isSubstitution ? { index } : { colInd, rowInd }

    const handlePlayerClick = () => {
        if (onPlayerClick) {
            if (isSubstitution || isSquadPlayer) {
                onPlayerClick({ player, position: { index } })
            } else {
                onPlayerClick({ player, position: { colIndex: colInd, rowIndex: rowInd } })
            }
        }
    }

    const isFakePlayer = !player.id || player.id.includes('fake')
    const opacity = (isPlayerActiveDroppable && !isSquadPlayer) || isCurrentActivePlayer ? 0.4 : 1

    return (
        <Box position="relative" opacity={opacity} transition="opacity 0.5s">
            <Draggable player={player} position={playerPosition} isSubstitution={Boolean(isSubstitution)}>
                <PlayerBasicInfo
                    player={player}
                    isHomeTeam={isHomeTeam}
                    isGoalKeeper={isGoalKeeper}
                    isCurrentActivePlayer={isCurrentActivePlayer}
                    handlePlayerClick={handlePlayerClick}
                    variant={variant}
                    isShirtNumberTaken={isShirtNumberTaken}
                />
            </Draggable>
            {!shouldHideDeleteButton && (
                <Button
                    onClick={() => handleDeletePlayerClick()}
                    size="xs"
                    position="absolute"
                    right={0}
                    top={-2}
                    p={0}
                    borderRadius="full"
                    variant="mirage">
                    <Icon as={IoMdClose} boxSize="0.8rem" color="sp.white" />
                </Button>
            )}
            {isFakePlayer && (
                <Icon as={FaCircle} boxSize="0.6rem" color="#FAAD14" position="absolute" left={2} top={0} p={0} />
            )}
        </Box>
    )
}
