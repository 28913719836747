import { Box, Text } from '@chakra-ui/react'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'

import { Droppable } from '@components/LineupAndSubsPreview/LineupEditModal/Droppable'

import { MissingPlayer, TPlayer } from '@common/types'

import { ListPlayer } from './ListPlayer'

interface SortablePlayerListProps {
    id: string
    list?: MissingPlayer[]
    currentActivePlayer: TPlayer
    updatePlayer: (player: MissingPlayer) => void
    deletePlayer: (player: MissingPlayer) => void
    handlePlayerClick?: (player: MissingPlayer) => void
}

export function SortablePlayersList({
    id,
    currentActivePlayer,
    list = [],
    updatePlayer,
    deletePlayer,
    handlePlayerClick,
}: SortablePlayerListProps) {

    return (
        <>
            <SortableContext items={list.map(player => player?.name)} strategy={verticalListSortingStrategy} id={id}>
                <Box
                    bg="sp.background"
                    borderRadius="16"
                    borderWidth="1px"
                    p={5}
                    overflow="visible"
                    w="100%"
                    minH="150px">
                    <Droppable id={id} data={currentActivePlayer} key={id}>
                        {list.length ? (
                            list.map((player, index) => (
                                <ListPlayer
                                    player={player}
                                    index={index}
                                    key={player?.name}
                                    id={player?.name}
                                    updatePlayer={updatePlayer}
                                    deletePlayer={deletePlayer}
                                    handlePlayerClick={handlePlayerClick}
                                />
                            ))
                        ) : (
                            <>
                                <Text align="center" size="sm">
                                    {' '}
                                    Player list is empty. Drag the players to add them!
                                </Text>
                            </>
                        )}
                    </Droppable>
                </Box>
            </SortableContext>
        </>
    )
}
